import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import { validateEmail } from '../../../hooks/utils'
import { Headline } from '../Presentation/Presentation'
import { s, colors, alpha } from '../../../style'
import { ButtonLink } from '../../button'

const options = [
  '3 Willa Roma',
  // '4A Apulia Bari',
  // '4B Apulia Lecce',
  // '5A Toskania Florencja',
  // '5B Toskania Siena',
  // '6A Sardynia Cagliari',
  '6B Sardynia Cervo',
]

const Form = () => {
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
  }

  const [option, setOption] = useState('3 Willa Roma')
  const [agreement, setAgreement] = useState(false)
  const [formData, setFormData] = useState(initialFormData)
  const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)

  const handleChangeOption = (event) => {
    setOption({ value: event.target.value })
  }

  const handleChangeForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const formDataEmail = formData.email

  const validate = useCallback(() => {
    return agreement && validateEmail(formDataEmail)
  }, [agreement, formDataEmail])

  useEffect(() => {
    if (validate()) setMailState('filled')
    else setMailState('unfilled')
  }, [validate, setMailState])

  // const xd = () => {
  //   function gtag_report_conversion(url) {
  //     var callback = function () {
  //       if (typeof url != 'undefined') {
  //         window.location = url
  //       }
  //     }
  //     gtag('event', 'conversion', {
  //       send_to: 'AW-304283430/OtcbCJD37YADEKb-i5EB',
  //       event_callback: callback,
  //     })
  //     return false
  //   }
  // }

  const sendMail = async () => {
    if (validate()) {
      setSuccess(null)
      setOpenBackdrop(true)
      setMailState('loading')
      // if (typeof window !== 'undefined') {
      //   if (process.env.NODE_ENV !== 'development')
      //     window.gtag('event', 'conversion', {
      //       send_to: 'AW-304283430/OtcbCJD37YADEKb-i5EB',
      //     })
      // }
      axios({
        method: 'POST',
        url:
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:5000/sendmail'
            : 'https://cleverserver.appclever.pl/sendmail',
        data: {
          //
          ...formData,
          option: option.value || 'nie wybrano',

          // config
          toEmail: 'ServerSide', //server side defined
          cc: [{ email: 'ServerSide' }], //sever side defined
          template: 'PalermoOliwaReserve',
        },
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setOpenBackdrop(true)
            setAgreement(false)
            setSuccess(true)
            setFormData(initialFormData)
            setOption(null)
            console.log('TRUE')
            console.log(res)

            console.log(process.env.NODE_ENV)
          } else {
            setOpenBackdrop(true)
            setSuccess(false)
            console.log('FALSE')
            console.log(res)
            console.log(process.env.NODE_ENV)
            setFormData(initialFormData)
          }
        })
        .catch((error) => {
          setOpenBackdrop(true)
          setSuccess(false)
          console.log('ERROR')
          console.log(error)
          console.log(process.env.NODE_ENV)
        })
    }
  }

  const resetAll = () => {
    setOpenBackdrop(false)
    setSuccess(undefined)
    setFormData(initialFormData)
    setAgreement(false)
  }

  return (
    <>
      <section id="kontakt" css={sSection}>
        <Headline
          noShadow
          title="Zainteresowany? Poinformuj nas!"
          desc="Wypełnij poniższy formularz, aby wysłać nam wiadomość związaną z rezerwacją wybranego apartamentu. Odezwiemy się do Ciebie drogą mailową."
          extraCss={{
            '.headline-desc': {
              [s.md]: { maxWidth: '75%' },
              [s.lg]: { maxWidth: '50%' },
            },
          }}
        />
        <div
          css={[
            sForm,
            formDataEmail &&
              !validateEmail(formDataEmail) && {
                "input[name='email']": {
                  backgroundColor: 'rgba(255,0,0,0.16) !important',
                  color: 'red !important',
                },
              },
          ]}>
          <div>
            <p>Imię</p>
            <input
              id="name"
              name="name"
              type="text"
              onChange={handleChangeForm}
            />
          </div>
          <div>
            <p>Email</p>
            <input
              id="email"
              name="email"
              type="email"
              required
              onChange={handleChangeForm}
            />
          </div>
          <div>
            <p>Telefon</p>
            <input
              id="phone"
              name="phone"
              type="tel"
              pattern="[0-9]{3} [0-9]{3} [0-9]{3}"
              onChange={handleChangeForm}
            />
          </div>
          <div>
            <p>Willa</p>
            <select id="building" name="building" onBlur={handleChangeOption}>
              {options.map((option, id) => (
                <option key={id} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <section
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <input
              type="checkbox"
              id="agreement"
              name="agreement"
              checked={agreement}
              onChange={() => setAgreement(!agreement)}
            />
            <p css={{ marginLeft: '1rem', fontSize: '0.875rem', opacity: 0.3 }}>
              Wyrażam zgodę na otrzymywanie zgodnie z ustawą z dnia 18 lipca
              2002 r. o świadczeniu usług drogą elektroniczną (Dz.U. z 2002 r.
              Nr 144, poz. 1204 z późn. zm.) na otrzymywanie za pomocą poczty
              elektronicznej informacji związanych ze złożonym zapytaniem przez
              Palermo Investment Sp. z o.o. z siedzibą w Gdańsku przy Al.
              Grunwaldzka 472B.
            </p>
          </section>
          <div>
            <ButtonLink
              id={
                mailState === 'filled'
                  ? 'send_form_button_id'
                  : 'form-button-fill'
              }
              onClickFunc={sendMail}
              extraCss={[sButton]}
              color={
                mailState === 'filled' ? colors.sectionBackground : '#6b6b6b'
              }>
              {mailState === 'filled'
                ? 'Wyślij!'
                : validateEmail(formDataEmail)
                ? !agreement
                  ? 'Zaznacz zgodę'
                  : 'Podaj Email'
                : 'Wypełnij pola'}
            </ButtonLink>
          </div>
        </div>
        {/* <h1>mailState: {mailState}</h1>
      <h1>success: {success === null ? 'null' : success ? 'true' : 'false'}</h1>
      <h1>
        openBackdrop:{' '}
        {openBackdrop === null ? 'null' : openBackdrop ? 'false' : 'false'}
      </h1> */}
      </section>{' '}
      <div
        css={[
          openBackdrop ? { opacity: 1 } : { opacity: 0, pointerEvents: 'none' },
          {
            display: 'flex',
            position: 'fixed',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            left: 0,
            zIndex: 999,
            width: '100vw',
            height: '100vh',
            transition: 'opacity 0.3s ease-out',
            backgroundColor: colors.white.concat(alpha[95]),
            div: {
              maxWidth: 960,
            },
          },
        ]}
        onClick={resetAll}
        onKeyPress={resetAll}
        tabIndex={0}
        role="button">
        {success === null && <Headline noShadow title="Wysyłanie..." />}
        {success === true && (
          <Headline
            success
            noShadow
            title="Dziękujemy!"
            desc="Twoja wiadomość została przesłana. Na podany przez Ciebie adres email wysłaliśmy potwierdzenie. Skontaktujemy się z Tobą najszybciej jak to możliwe"></Headline>
        )}
        {success === false && (
          <Headline
            noShadow
            title="Niepowodzenie"
            desc="Niestety Twoja wiadomość nie została wysłana. Prosimy skontaktować się poprzez pocztę email lub telefonicznie"></Headline>
        )}
        {success !== null && (
          <ButtonLink onClickFunc={resetAll} color={colors.text}>
            OK
          </ButtonLink>
        )}
      </div>
    </>
  )
}

const sForm = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0 auto',
  maxWidth: 960,

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    p: {
      fontSize: '1rem',
      marginBottom: '0.5rem',
      fontWeight: 700,
    },
    'input, select, checkbox': {
      transition: 'color 0.3s ease-out, background-color 0.3s ease-out',
      display: 'flex',
      fontFamily: 'inherit',
      fontSize: '100%',
      lineHeight: 1.15,
      margin: 0,
      overflow: 'visible',
      border: 'none',
      outline: 'none',
      backgroundColor: colors.text.concat(alpha[8]),
      padding: '0.75rem 1rem',
      marginBottom: '1.5rem',
    },
  },
}

const sSection = {
  [s.xs]: {
    margin: '3rem 0rem',
    padding: '3rem 2rem',
  },
  [s.sm]: {
    margin: '3rem 0rem',
    padding: '3rem 4rem',
  },
  [s.md]: {
    margin: '5rem 0rem',
    padding: '5rem 4rem',
  },
  [s.lg]: {
    margin: '8rem 0rem',
    padding: '8rem 4rem',
  },
}

const sButton = {
  width: 'max-content',
  margin: '0 auto',
  marginTop: '4rem',
  cursor: 'pointer',
  fontWeight: 700,
}

export default Form
