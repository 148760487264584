import React from 'react'

import Cookies from './cookies'
import Header from './header'
import Footer from './footer'

import '../style/constants/style.css'
import Menu from '../components/menu'

const Layout = ({ children }) => {
  return (
    <div css={sLayout}>
      <Header />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
      <Cookies />
      <Menu />
    </div>
  )
}

const sLayout = {
  fontFamily: 'Poppins',
}

export default Layout
