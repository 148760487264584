// COLORS DEFINITION
// For main colors use Shades at https://color.adobe.com/create/color-wheel

export const colors = {
  white: '#ffffff',
  black: '#000000',

  //CED722
  estetic: '#ffffff',
  mainLighter: '#353739',
  mainLight: '#6A7531',
  main: '#556B2F',
  brand: '#556B2F',
  mainDark: '#45511C', // experimental

  text: '#353739',
  sectionBackground: '#003263',

  primary: '#003263',
  secondary: '#556B2F',

  themeColor: '#003263',
  yellow: '#F2FF00',
}
