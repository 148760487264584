import React from 'react'
import { Link } from 'gatsby'
import { s, alpha } from '../style'

export const ButtonLink = ({
  id,
  children,
  to,
  extraCss,
  color = '#ffffff',
  center,
  onClickFunc,
  external,
}) => {
  if (onClickFunc)
    return (
      <div
        id={id}
        onClick={onClickFunc}
        onKeyPress={onClickFunc}
        tabIndex={0}
        role="button"
        css={[
          sButtonLink,
          {
            color: color,
            boxShadow: `inset 0px 0px 0px 1px ${color}`,
            '&:hover': {
              boxShadow: `inset 0px 0px 0px 100px ${color.concat(alpha[30])}`,
            },
          },
          center && { width: '100%' },
          extraCss,
        ]}>
        {children}
      </div>
    )

  if (external)
    return (
      <a
        id={id}
        href={to}
        target="_blank"
        rel="noreferrer noopenner"
        css={[
          sButtonLink,
          {
            color: color,
            boxShadow: `inset 0px 0px 0px 1px ${color}`,
            '&:hover': {
              boxShadow: `inset 0px 0px 0px 100px ${color.concat(alpha[30])}`,
            },
          },
          center && { width: '100%' },
          extraCss,
        ]}>
        {children}
      </a>
    )

  return (
    <Link
      id={id}
      to={to}
      css={[
        sButtonLink,
        {
          color: color,
          boxShadow: `inset 0px 0px 0px 1px ${color}`,
          '&:hover': {
            boxShadow: `inset 0px 0px 0px 100px ${color.concat(alpha[30])}`,
          },
        },
        center && { width: '100%' },
        extraCss,
      ]}>
      {children}
    </Link>
  )
}

const sButtonLink = {
  willChange: 'box-shadow',
  transition: 'box-shadow 0.6s ease-out',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontSize: '1rem',
  padding: '1rem 3rem',
  [s.xs]: {
    fontSize: '0.875rem',
  },
  letterSpacing: '0.5em',
  cursor: 'pointer',
}
