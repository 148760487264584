import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import logo from '../assets/palermo-investment-horizontal-logo'
import { s, colors, alpha } from '../style'

const Footer = () => {
  const {
    footer: {
      frontmatter: {
        footer_email,
        footer_phone,
        footer_adress: { city, post_code, street, lat, lng },
      },
    },
  } = useStaticQuery(graphql`
    query {
      footer: markdownRemark(
        fileAbsolutePath: { regex: "/content/footer/footer.md/" }
      ) {
        frontmatter {
          footer_email
          footer_phone
          footer_adress {
            city
            post_code
            street
            lat
            lng
          }
        }
      }
    }
  `)
  // const car_navigation = ''
  const google_map_link = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`

  return (
    <footer css={{ width: '100vw', backgroundColor: colors.text }}>
      <div css={sFooter}>
        <Link to="/" css={sLogo}>
          {logo}
        </Link>
        {/* <a href={`tel:${footer_phone}`} css={{ fontWeight: 700 }}>
          {footer_phone}
        </a> */}
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',

            [s.sm_down]: { marginBottom: '2rem' },
          }}>
          <div css={informations}>
            <p>Email:</p>{' '}
            <a
              href={`mailto:${footer_email}`}
              css={{ fontWeight: 700 }}
              // onClick={() => {
              //   if (typeof window !== 'undefined') {
              //     window.gtag('event', 'conversion', {
              //       send_to: 'AW-304283430/OtcbCJD37YADEKb-i5EB',
              //     })
              //   }
              // }}
            >
              {footer_email}
            </a>
          </div>
          <div css={informations}>
            <p>Telefon: </p>{' '}
            <a
              href={`tel:+48513185469`}
              css={{ fontWeight: 700 }}
              id="call_button_id2"
              // onClick={() => {
              //   if (typeof window !== 'undefined') {
              //     window.gtag('event', 'conversion', {
              //       send_to: 'AW-304283430/hmGHCKvK7YADEKb-i5EB',
              //     })
              //   }
              // }}
            >
              {footer_phone}
            </a>
          </div>
          {/* <div css={informations}>
            <p>Adres: </p>{' '}
            <a
              href={`${google_map_link}`}
              css={{ fontWeight: 700 }}
              target="_blank">
              {city},{post_code}
              <div css={{ display: 'flex', flexDirection: 'column' }}>
                <div>{street}</div>
              </div>
            </a>
          </div> */}
        </div>

        <a
          href="https://rejestr.io/krs/494198/palermo-investment"
          target="_blank"
          rel="noreferrer"
          css={[
            {
              fontSize: '0.75rem',
              '&:hover strong': { color: colors.white },
            },
            centerMd,
          ]}>
          Copyright © {new Date().getFullYear()}{' '}
          <strong
            css={{
              transition: 'color 0.3s ease-out',
              color: colors.white.concat(alpha[60]),
            }}>
            &nbsp; Palermo Investment &nbsp;
          </strong>{' '}
          Sp. z o.o.
        </a>
      </div>
    </footer>
  )
}

const sFooter = {
  display: 'flex',
  flexWrap: 'wrap',

  width: '100%',
  maxWidth: 1280,
  padding: '2rem 2rem 4rem',
  boxSizing: 'border-box',
  margin: '0 auto',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: colors.white.concat(alpha[30]),
  fontSize: '0.875rem',
  [s.sm_down]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
  [s.xs]: {
    padding: '4rem 2rem 6rem',
  },
  a: {
    transition: 'color 0.3s ease-out',
    flexGrow: 0,
    [s.sm_down]: {
      marginBottom: '2rem',
    },
  },
  'a:hover': {
    color: colors.white.concat(alpha[100]),
  },
}

const centerMd = {
  [s.md_only]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '4rem',
  },
}

const sLogo = {
  svg: { height: 32 },
}
const informations = {
  display: 'flex',
  flexDirection: 'column',
  'p,a': {
    textAlign: 'center',
  },

  [s.md]: {
    flexDirection: 'row',

    'p,a': {
      textAlign: 'unset',
      marginBottom: '6px',
    },
    p: {
      marginRight: 6,
    },
  },
}

export default Footer
