import React, { useState } from 'react'
import { Link } from 'gatsby'
import { colors, s, alpha } from '../style'
import { Form } from './Index'

const Menu = () => {
  const [formOpen, setFormOpen] = useState(false)
  return (
    <>
      <div css={sOuter}>
        <Link to="/#oferta" css={sButton}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
          Sprawdź
        </Link>
        <a
          href="tel:+48513185469"
          css={sButton}
          id="call_button_id1"
          // onClick={
          //   () => {
          //   if (typeof window !== 'undefined') {
          //     window.gtag('event', 'conversion', {
          //       send_to: 'AW-304283430/hmGHCKvK7YADEKb-i5EB',
          //     })
          //   }
          // }
          // }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          Zadzwoń
        </a>
        <div
          css={sButton}
          tabIndex={0}
          role="button"
          onClick={() => setFormOpen(!formOpen)}
          onKeyPress={() => setFormOpen(!formOpen)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
              fillRule="evenodd"
              d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
              clipRule="evenodd"
            />
          </svg>
          Napisz
        </div>
      </div>
      <div
        css={[sFormPopUp, !formOpen && { opacity: 0, pointerEvents: 'none' }]}>
        <div
          css={[sDimmer, !formOpen && { opacity: 0, pointerEvents: 'none' }]}
          onClick={() => setFormOpen(false)}
        />
        <svg
          onClick={() => setFormOpen(false)}
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          css={sCloseButton}
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
        {formOpen && <Form />}
      </div>
    </>
  )
}

const sCloseButton = {
  cursor: 'pointer',

  width: '1.5rem',
  height: '1.5rem',
  position: 'fixed',
  top: '1rem',
  right: '1rem',
  zIndex: 999,
}

const sDimmer = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: colors.primary,
  opacity: 0.5,
  transition: 'opacity 0.3s ease-out',
  cursor: 'pointer',
}

const sFormPopUp = {
  transition: 'opacity 0.3s ease-out',
  position: 'fixed',
  top: 0,
  left: 0,
  // left: '50%',
  // transform: 'translateX(-50%)',
  zIndex: 997,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'scroll',
  width: '100vw',
  height: '100vh',
  // pointerEvents: 'none',
  // maxHeight: '100vh',
  // marginTop: 128,
  '& > section': {
    zIndex: 998,
    pointerEvents: 'initial',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    '.headline-desc': {
      [s.md]: { maxWidth: '100% !important', width: '100%', margin: 0 },
      [s.lg]: { maxWidth: '100% !important', width: '100%', margin: 0 },
    },
    [s.xs]: {
      margin: '0 !important',
      paddingBottom: '10rem !important',
      paddingTop: '4rem !important',
    },
    [s.sm]: {
      margin: '0 !important',
      paddingBottom: '10rem !important',
    },
  },
}

const sOuter = {
  position: 'fixed',
  bottom: 0,
  right: 0,
  [s.sm_down]: {
    width: '100vw',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 998,
  [s.md]: {
    bottom: 'auto',
    top: '50%',
    transform: 'translateY(-50%)',
    flexDirection: 'column',
  },
  boxShadow: `-2px 4px 32px ${colors.text.concat(alpha[30])}`,
}

const sButton = {
  cursor: 'pointer',

  flexDirection: 'column',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  letterSpacing: '0.1em',
  transition: 'color 0.3s ease-out, filter 0.3s ease-out',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '4rem',
  backgroundColor: colors.primary,
  color: 'white',
  fontSize: '0.5rem',
  svg: {
    width: '1.25rem',
    height: '1.25rem',
    marginBottom: '0.25rem',
  },
  '&:hover': {
    filter: 'brightness(1.5)',
    color: colors.yellow,
  },
  [s.sm_down]: {
    maxWidth: '33.33%',
    flexBasis: '33.33%',
  },
  [s.md]: {
    height: '5rem',
    width: '5rem',
  },
}

export default Menu
