import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'
import { colors, s, alpha } from '../../../style'

const Desc = () => {
  const {
    desc: {
      frontmatter: { description_index },
    },
  } = useStaticQuery(graphql`
    query {
      desc: markdownRemark(
        fileAbsolutePath: { regex: "/content/index/desc/desc.md/" }
      ) {
        frontmatter {
          description_index {
            title
            subtitle
            body
            image {
              name
              childImageSharp {
                fluid(
                  maxWidth: 960
                  quality: 100
                  toFormat: JPG
                  jpegProgressive: true
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div css={sSections}>
      {description_index.map(({ title, subtitle, body, image }, id) => (
        <div key={id} css={sSection}>
          <div
            css={[
              id % 2 === 0
                ? { [s.lg]: { order: 0 } }
                : { [s.lg]: { order: 2 } },
              sText,
            ]}>
            <p css={sSubtitle}>{subtitle}</p>
            <h1 css={sTitle}>{title}</h1>
            <ReactMarkdown css={sBody}>{body}</ReactMarkdown>
          </div>
          <div
            css={[
              sImageContainer,
              id % 2 === 0
                ? { [s.lg]: { order: 2, marginLeft: '6rem' } }
                : { [s.lg]: { order: 0, marginRight: '6rem' } },
            ]}>
            <Img
              fluid={image?.childImageSharp?.fluid}
              alt={title}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

const sSections = {
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: 1600,
  [s.md]: { padding: '4rem', paddingBottom: '0rem' },
  margin: '0 auto',
  marginBottom: '10rem',
  [s.xs]: {
    marginBottom: '0rem',
    marginTop: '6rem',
  },
  color: colors.text,
}

const sSection = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginTop: '10rem',
  [s.md_down]: {
    flexDirection: 'column',
  },
}

const sImageContainer = {
  flexShrink: 0,
  position: 'relative',
  [s.md_down]: {
    width: 'calc(100vw - 8rem)',
    height: 'calc(calc(9 * calc(100vw - 8rem)) / 16)',
    marginTop: '4rem',
  },
  [s.sm_down]: {
    width: '100vw',
    height: 'calc(900vw / 16)',
    marginTop: '4rem',
  },
  [s.lg]: {
    width: 600,
    height: 600,
  },
  boxShadow: `12px 12px 32px ${colors.text.concat(alpha[16])}`,
}

const sText = {
  [s.sm_down]: {
    padding: '0 2rem',
  },
}

const sSubtitle = {
  textTransform: 'uppercase',
  opacity: 0.6,
  paddingBottom: '1rem',
  [s.md]: {
    fontSize: '1.125rem',
  },
}

const sTitle = {
  fontFamily: 'Butler',
  fontWeight: 'bolder',
  [s.xs]: {
    fontSize: '2.5rem',
  },
  [s.sm]: {
    fontSize: '3rem',
  },
  [s.md]: {
    fontSize: '4rem',
  },
}

const sBody = {
  paddingTop: '2rem',
  p: {
    paddingTop: '2rem',
    lineHeight: '2em',
    color: colors.text.concat(alpha[60]),
    [s.sm_down]: { color: colors.text.concat(alpha[80]) },
    [s.xs]: { fontSize: '0.875rem', color: colors.text.concat(alpha[60]) },
    '&:hover': {
      a: { color: colors.text },
    },
  },
  a: {
    transition: 'color 0.3s ease-out',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}

export default Desc
