import React from 'react'
import { Link } from 'gatsby'
import logo from '../assets/palermo-investment-horizontal-logo'
import { s } from '../style'

const Header = () => {
  return (
    <div
      css={{
        boxSizing: 'border-box',
        overflowX: 'hidden',
        // position: 'fixed',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        zIndex: 100,
        height: 128,
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        padding: '0 4rem',
        // mixBlendMode: 'difference',
        // '@supports (mix-blend-mode: exclusion)': {
        //   mixBlendMode: 'multiply',
        // },
        // '@supports not(mix-blend-mode: difference)': {
        //   position: 'absolute',
        // },

        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        // backdropFilter: 'blur(24px)',
        // background:
        //   'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
        [s.xs]: {
          height: 96,
          justifyContent: 'center',
          padding: 0,
        },
      }}>
      <Link
        to="/"
        css={{ filter: `drop-shadow( 1px 2px 12px rgba(0, 0, 0, 0.3))` }}>
        {logo}
      </Link>
    </div>
  )
}

export default Header
