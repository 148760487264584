import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { s } from '../../../style'

const Map = () => {
  const {
    image: { childImageSharp: mapimage },
  } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "map-example.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      css={{
        position: 'relative',
        width: '100vw',
        maxHeight: '75vh',
        [s.sm_down]: { height: 400 },
        [s.md]: { height: 720 },
      }}>
      <Img
        fluid={mapimage.fluid}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        alt="map"
      />
    </div>
  )
}

export default Map
