import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="173.922"
    height="123.691"
    viewBox="0 0 173.922 123.691">
    <g
      id="Oliwa_Central"
      dataname="Oliwa Central"
      transform="translate(-780.5 -1352.883)">
      <path
        id="Path_1115"
        dataname="Path 1115"
        d="M-2874.627-281.888s3.516,1.1,3.819,1.074c1.816-.428,6.355,6.54,6.355,6.54a47.665,47.665,0,0,1,3.387,5.92,13.34,13.34,0,0,1,.964,5.042,3,3,0,0,0-.032.523c.077,0,.151,0,.225.006l.5.054a5.444,5.444,0,0,1,3.861,3.246,11.246,11.246,0,0,1,.866,6.449,11.426,11.426,0,0,1-2.44,5.728,5.78,5.78,0,0,1-4.309,2.287,4.327,4.327,0,0,1-.611-.043c-2.642-.376-4.622-3.15-4.928-6.9a23.226,23.226,0,0,1,.071-2.654l.006-.006.01-.008a8.848,8.848,0,0,1,.364-1.847c0-.007,0-.014.006-.021l.016-.051,0-.008v-.014a10.208,10.208,0,0,1,2.8-4.7,5.711,5.711,0,0,1,2.791-1.444,12.444,12.444,0,0,0-1.031-5.28,15.488,15.488,0,0,0-1.1-1.906,50.752,50.752,0,0,0-5.406-6.767c-2.431-2.317-2.07-2.478-4.355-2.79S-2875.438-281.709-2874.627-281.888Z"
        transform="translate(3648.225 2102.722) rotate(9)"
        fill="currentColor"
      />
      <path
        id="Path_1133"
        dataname="Path 1133"
        d="M24.7-45.632c-14.08,0-22.08,10.752-22.08,23.424,0,12.736,8,22.784,22.08,22.784s22.08-10.048,22.08-22.784C46.784-34.88,38.784-45.632,24.7-45.632Zm0,45.12c-7.616,0-11.136-4.1-11.136-21.7,0-17.536,3.52-22.336,11.136-22.336,7.552,0,11.008,4.8,11.008,22.336C35.712-4.608,32.256-.512,24.7-.512ZM62.336-7.744v-40l-11.2,2.368v1.088c2.56.96,3.2,1.92,3.2,4.16V-7.744c0,3.264-.128,5.952-2.56,6.656V0H64.9V-1.088C62.656-1.664,62.336-4.224,62.336-7.744ZM79.424-39.616a4.371,4.371,0,0,0-4.416-4.352,4.316,4.316,0,0,0-4.352,4.352,4.316,4.316,0,0,0,4.352,4.352A4.371,4.371,0,0,0,79.424-39.616Zm.192,31.808V-30.144l-11.2,2.56V-26.5c2.624,1.408,3.264,2.3,3.264,4.48V-7.808c0,3.264-.128,5.952-2.56,6.72V0H82.24V-1.088C79.936-1.728,79.616-4.224,79.616-7.808Zm44.1-21.76v1.088c7.1,0,3.392,9.664,3.392,9.664L123.84-10.3,117.184-24.32s-2.368-4.16,1.664-4.16v-1.088H103.68v1.088c1.088,0,2.624,0,4.1,2.752,0,0,.256.448,2.176,4.672l-4.1,10.752L99.2-24.32s-2.176-4.16,1.6-4.16v-1.088H85.7v1.088c1.024,0,2.624,0,4.032,2.752,0,0,.9,1.728,12.224,26.3h1.088l7.552-20.288L119.936.576h1.024l7.36-19.136c3.584-9.792,6.784-9.92,7.936-9.92v-1.088ZM154.048-6.592a4.734,4.734,0,0,1-4.224,3.264c-4.16,0-4.16-4.224-4.16-4.224,0-3.264,2.112-6.272,8.384-7.68Zm11.136,3.264c-3.136,0-3.072-4.48-3.072-4.48v-9.536c0-7.552-2.3-12.8-10.688-12.8a12.749,12.749,0,0,0-6.208,1.28,10.464,10.464,0,0,0-4.992,4.672,3.778,3.778,0,0,0,.192,3.84,3.679,3.679,0,0,0,5.056.96,3.621,3.621,0,0,0,.96-5.056,3.307,3.307,0,0,0-2.368-1.408c-.448-.064-.192-1.152,1.856-2.176,4.416-2.3,8.128,1.024,8.128,7.68v3.968c-11.2,2.816-16.576,3.456-16.576,10.432,0,6.528,7.36,6.528,7.36,6.528,4.544,0,8.32-2.368,9.28-5.184.7,5.184,6.4,5.184,6.4,5.184,4.032,0,6.976-2.5,7.488-4.544l-1.088-.448A1.892,1.892,0,0,1,165.184-3.328Z"
        transform="translate(786.422 1446.466)"
        fill="currentColor"
      />
      <path
        id="Path_1132"
        dataname="Path 1132"
        d="M7.524.108A6.877,6.877,0,0,1,4.149-.72a6.063,6.063,0,0,1-2.358-2.3A6.4,6.4,0,0,1,.936-6.3a6.4,6.4,0,0,1,.855-3.285A6.047,6.047,0,0,1,4.158-11.88a6.928,6.928,0,0,1,3.384-.828,7.011,7.011,0,0,1,2.592.468,5.373,5.373,0,0,1,2.016,1.368l-.846.846A5.019,5.019,0,0,0,7.578-11.52a5.476,5.476,0,0,0-2.718.684A5,5,0,0,0,2.943-8.964,5.189,5.189,0,0,0,2.25-6.3a5.189,5.189,0,0,0,.693,2.664A5,5,0,0,0,4.86-1.764a5.476,5.476,0,0,0,2.718.684A4.951,4.951,0,0,0,11.3-2.592l.846.846A5.449,5.449,0,0,1,10.125-.369,6.963,6.963,0,0,1,7.524.108Zm22.32-1.26V0h-8.91V-12.6h8.64v1.152H22.266v4.482h6.516v1.134H22.266v4.68ZM49.446-12.6V0h-1.1l-8.1-10.224V0H38.916V-12.6h1.1L48.132-2.376V-12.6Zm12.492,1.152H57.51V-12.6H67.7v1.152H63.27V0H61.938ZM84.366,0l-2.88-4.05A9.127,9.127,0,0,1,80.478-4H77.094V0H75.762V-12.6h4.716a5.691,5.691,0,0,1,3.78,1.152A3.92,3.92,0,0,1,85.626-8.28a4.1,4.1,0,0,1-.747,2.493A4.138,4.138,0,0,1,82.746-4.32L85.824,0ZM80.442-5.13A4.353,4.353,0,0,0,83.3-5.958a2.86,2.86,0,0,0,.99-2.322,2.875,2.875,0,0,0-.99-2.349,4.386,4.386,0,0,0-2.862-.819H77.094V-5.13Zm22.176,1.764H95.6L94.086,0H92.7l5.76-12.6h1.314L105.534,0h-1.4Zm-.486-1.08-3.024-6.768L96.084-4.446Zm11.43-8.154h1.332V-1.152h7.056V0h-8.388Z"
        transform="translate(809.922 1476.466)"
        fill="currentColor"
      />
      <path
        id="Path_1117"
        dataname="Path 1117"
        d="M673.829,681.651c-1.378-6.11,1.573-13.531,5.958-18.653a27.843,27.843,0,0,0-4.879,15.3c3.3-7.964,10.508-16.065,13.326-23.726-11.184,2.471-18.63,14.642-15.966,25.7.137.592,1.512,3.65,1.512,3.65A3.137,3.137,0,0,0,673.829,681.651Z"
        transform="matrix(-0.174, 0.985, -0.985, -0.174, 1619.525, 829.87)"
        fill="currentColor"
      />
      <path
        id="Path_848"
        dataname="Path 848"
        d="M661.539,665.057a51.824,51.824,0,0,1-4.145-25.808q.049-.422.109-.844c-.023,3.554.176,13.036,2.733,19.756a80.933,80.933,0,0,0,.383-31.877,28.462,28.462,0,0,0-6.913,19.262,27.588,27.588,0,0,0,4.213,13.523,59.621,59.621,0,0,0,2.038,6.627C660.769,667.836,661.743,665.526,661.539,665.057Z"
        transform="translate(1477.955 678.551) rotate(87)"
        fill="currentColor"
      />
      <path
        id="Path_848-2"
        dataname="Path 848"
        d="M669.921,640.125a44.459,44.459,0,0,1,8.5-9.3c-2.68,2.733-7.954,8.634-10.768,15.338,7.892-6.149,15.6-13.271,19.885-22.214-11.633,2.2-23.225,11.652-23.1,23.88C663.665,649.787,667.075,644.382,669.921,640.125Z"
        transform="matrix(-0.208, 0.978, -0.978, -0.208, 1639.031, 867.552)"
        fill="currentColor"
      />
      <path
        id="Path_1116"
        dataname="Path 1116"
        d="M740.094,702.684h0a72.714,72.714,0,0,1-8.551,4.33,54.721,54.721,0,0,1-9.061,2.943,46.677,46.677,0,0,1-18.756.563,44.957,44.957,0,0,1-17.186-7.053,50.92,50.92,0,0,1-4.931-3.81c-2.3-2.011-6.271-6.536-6.271-6.536s-1.376-1.764-2.021-2.68a51.736,51.736,0,0,1-6.407-12.431c-.51-1.475-.973-2.968-1.34-4.487a45.837,45.837,0,0,1-.742-18.639c1.043-6.2-1.485-2.949-2.015-.383a46.584,46.584,0,0,0-.959,9.808c.016,1.918.395,5.74.395,5.74a53.1,53.1,0,0,0,3.113,12.112,50.463,50.463,0,0,0,5.361,10.076,49.088,49.088,0,0,0,14.15,13.782,46.986,46.986,0,0,0,8.9,4.376,46.157,46.157,0,0,0,19.431,2.669,49.443,49.443,0,0,0,9.63-1.733,55.605,55.605,0,0,0,9.093-3.462,69.935,69.935,0,0,0,8.381-4.847.2.2,0,0,0-.213-.34Z"
        transform="translate(1777.682 1169.542) rotate(122)"
        fill="currentColor"
      />
    </g>
  </svg>
)
