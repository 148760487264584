import React, { useEffect } from 'react'
import { Viewer } from 'photo-sphere-viewer'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import { s } from '../../../style'
import image from './panorama.jpg'

const Panorama = () => {
  const sphereElementRef = React.createRef()

  useEffect(() => {
    const shperePlayerInstance = new Viewer({
      container: sphereElementRef.current,
      panorama: image,
      // 'https://upload.wikimedia.org/wikipedia/commons/f/f0/Colonia_Ulpia_Traiana_-_Rekonstruktion_r%C3%B6mischer_Schiffe-0010560.jpg',
    })

    // unmount component instructions
    return () => {
      shperePlayerInstance.destroy()
    }
  }, []) // will only be called when the src prop gets updated

  return (
    <div>
      <div ref={sphereElementRef} id="viewer" css={sContainer} />
    </div>
  )
}

const sContainer = {
  '.psv-canvas-container': {
    width: '100% !important',
    height: '100% !important',
  },
  width: '100%',
  position: 'relative',
  top: 0,
  left: 0,
  canvas: {
    width: '100%',
    height: '100%',
  },
  [s.sm_down]: {
    height: 'calc(900vw / 16)',
  },
  [s.md]: {
    height: 500,
  },
}

export default Panorama
