import React from 'react'
import { colors, s } from '../../../style'

const MapHTML = () => {
  return (
    <>
      <p
        css={{
          color: colors.text,
          margin: '4rem 2rem',
          fontSize: '2.5rem',
          textAlign: 'center',
          fontFamily: 'Butler',
          [s.md]: { fontSize: '4rem', margin: '2rem 4rem 4rem' },
        }}>
        Już w sprzedaży!
      </p>
      <div css={sStyle}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2321.898133705395!2d18.577057916050947!3d54.411775002072645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd0b4bd6ce3643%3A0xa6607ead955eed7e!2sOliwa%20Central!5e0!3m2!1spl!2spl!4v1617709851054!5m2!1spl!2spl"
          css={{ border: 'none', width: '100%', height: '100%' }}
          allowfullscreen=""
          loading="lazy"></iframe>
      </div>
    </>
  )
}

const sStyle = {
  width: '100vw',
  height: 460,
}

export default MapHTML
