import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import CountUp from 'react-countup'
import { s } from '../../../style'
import VisibilitySensor from 'react-visibility-sensor'

const Stats = () => {
  const {
    stats: {
      frontmatter: { stats_index },
    },
    image: { childImageSharp: stats_image },
  } = useStaticQuery(graphql`
    query {
      stats: markdownRemark(
        fileAbsolutePath: { regex: "/content/index/stats/stats.md/" }
      ) {
        frontmatter {
          stats_index {
            value
            text
            unit
          }
        }
      }
      image: file(relativePath: { eq: "stats-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div css={sSection}>
      <Img
        fluid={stats_image.fluid}
        alt="house"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          height: '100%',
          transform: 'translate(-50%, -50%)',
        }}
        // imgStyle={{ filter: 'blur(24px)' }}
      />
      <div
        css={[
          sAbsolute,
          {
            backdropFilter: 'blur(24px)',
          },
        ]}
      />
      <div css={[sAbsolute, sGridContainer]}>
        {stats_index.map(({ value, unit, text }, id) => (
          <div key={id} css={sGridItem}>
            <div css={sGridItem.value}>
              <CountUp start={value / 4} end={value} duration={3} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor
                    partialVisibility={true}
                    onChange={start}
                    delayedCall
                    offset={{ top: 0 }}>
                    <span ref={countUpRef}>{value}</span>
                  </VisibilitySensor>
                )}
              </CountUp>
              {unit}
            </div>
            <span css={sGridItem.text}>{text}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

const sSection = {
  display: 'flex',
  position: 'relative',
  width: '100vw',
  // height: 'max-content',
  height: '100%',
  // [s.md]: { height: 500 },
  [s.xs]: { height: 480, padding: '2rem 0' },
  [s.sm]: { height: 400 },
  [s.md]: { height: 400 },
  boxShadow: '0px 8px 32px rgba(0,0,0,0.3)',
}

const sAbsolute = {
  zIndex: 1,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}

const sGridContainer = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  [s.md_only]: { paddingLeft: '2rem' },
}

const sGridItem = {
  height: 'max-content',
  color: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 0,
  [s.xs]: {
    maxWidth: '100%',
    flexBasis: '100%',
  },
  [s.sm]: {
    maxWidth: '50%',
    flexBasis: '50%',
  },
  [s.lg]: {
    maxWidth: '25%',
    flexBasis: '25%',
  },
  value: {
    display: 'flex',
    fontFamily: 'Butler',
    fontSize: 96,
    [s.sm_down]: {
      fontSize: 64,
      minWidth: 120,
      width: 120,
      maxWidth: 120,
      justifyContent: 'flex-end',
      textAlign: 'right',
      fontSize: 48,
    },
    // [s.xs]: {

    // },
    [s.md]: {
      fontSize: 72,
      minWidth: 160,
      width: 160,
      maxWidth: 160,
      justifyContent: 'flex-end',
      textAlign: 'right',
    },
    [s.xl]: { fontSize: 96, minWidth: 200, width: 200, maxWidth: 200 },
  },
  text: {
    marginLeft: '1rem',
    width: 160,
  },
}

export default Stats
